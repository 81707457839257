import theme from "../../config/theme"
import '../../css/Activitys/Activitys.css'
import { useLocation, useHistory } from "react-router-dom";
import { useEffect } from 'react';

// AWARNESS
import AWARNESS from '../../assets/AWARNESS/awarnessprogram.jpg';
import AWARNESS1 from '../../assets/AWARNESS/img1.jpg';
import AWARNESS2 from '../../assets/AWARNESS/img4.jpg';
import AWARNESS3 from '../../assets/AWARNESS/img5.jpg';
import AWARNESS4 from '../../assets/AWARNESS/img6.jpg';

// JUTE CRAFT TRAINING
import JUTECRAFT from '../../assets/JUTECRAFT/JUTECRAFT.jpg';

// BAMBOO CRAFT TRAINING
import BAMBOO from '../../assets/BAMBOO/BAMBOO.jpg';
import BAMBOO1 from '../../assets/BAMBOO/img10.jpg';
import BAMBOO2 from '../../assets/BAMBOO/img11.jpg';
import BAMBOO3 from '../../assets/BAMBOO/img12.jpg';
import BAMBOO4 from '../../assets/BAMBOO/img13.jpg';
import BAMBOO5 from '../../assets/BAMBOO/img14.jpg';
import BAMBOO6 from '../../assets/BAMBOO/img16.jpg';
import BAMBOO7 from '../../assets/BAMBOO/img2.jpg';
import BAMBOO8 from '../../assets/BAMBOO/img6.jpg';
import BAMBOO9 from '../../assets/BAMBOO/img7.jpg';
import BAMBOO10 from '../../assets/BAMBOO/img8.jpg';

// TRAINING ON BASIC EDUCATION
import BASICEDUCATION from '../../assets/BASICEDUCATION/BASICEDUCATION.jpg';

// GROUP ATRENGHTENING PROGRAMME
import ATRENGHTENING from '../../assets/ATRENGHTENING/ATRENGHTENING.jpg'


// WELFARE SCHEMES FOR ARTISANS
import ARTISANS from '../../assets/ARTISANS/ARTISANS.jpg';








const Images = [
    {
        id: 1,
        title: 'AWARNESS PROGRAMME',
        image: [AWARNESS, AWARNESS1, AWARNESS2, AWARNESS3, AWARNESS4]
    },
    {
        id: 2,
        title: 'JUTE CRAFT TRAINING',
        image: [JUTECRAFT]
    },
    {
        id: 3,
        title: 'BAMBOO CRAFT TRAINING',
        image: [BAMBOO,BAMBOO1,BAMBOO2,BAMBOO3,BAMBOO4,BAMBOO5,BAMBOO6,BAMBOO7,BAMBOO8,BAMBOO9,BAMBOO10]
    },
    {
        id: 3,
        title: 'TRAINING ON BASIC EDUCATION',
        image: [BASICEDUCATION]
    },
    {
        id: 4,
        title: 'GROUP ATRENGHTENING PROGRAMME',
        image: [ATRENGHTENING]
    },
    {
        id: 4,
        title: 'WELFARE SCHEMES FOR ARTISANS',
        image: [ARTISANS]
    }
]



function ActivityInDetail({ props }) {
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        if (!location.name) {
            history.push('/Activitys')
        }
    })

    return (
        <>
            <div className="home-gallery" style={{ backgroundColor: theme.colors.background1 }}>
                <div>
                    <h5 style={{ color: theme.colors.textColor }}>Home - Activitys</h5>
                </div>
                <div>
                    <h1 className="fifth">
                        <span className="seven">Our</span>  <span className="six">Activitys</span>
                    </h1>
                </div>
            </div>
            <div className='ActivityBlock'>
                <div className="quotes">
                    <h1 className="fontQuotes"><span className="six">{location.name} </span></h1>
                </div>
                <div className="parent">
                    <div className="child1">
                        <p style={{ color: 'black', fontSize: 16, marginTop: 5 }}>
                            {location.detail}
                        </p>
                    </div>
                </div>

                <div className="galleryimg" >
                    {Images.map((value, idx) => (
                        (value.title == location.name) ?
                            <div className='grid grid-cols-3 max-md:grid-cols-1 gap-y-8 max-lg:grid-cols-2'>
                                {
                                    value.image.map((value, index) => (
                                        <img  src={value} width={400} height={350} className='forgallerycard' />
                                    ))
                                }
                            </div> : <></>
                    ))}
                </div>
            </div >
        </>
    )
}

export default ActivityInDetail;