import React from "react";
import '../../css/Gallery/Gallery.css'


// AWARNESS
import AWARNESS from '../../assets/AWARNESS/awarnessprogram.jpg';
import AWARNESS1 from '../../assets/AWARNESS/img1.jpg';
import AWARNESS2 from '../../assets/AWARNESS/img4.jpg';
import AWARNESS3 from '../../assets/AWARNESS/img5.jpg';
import AWARNESS4 from '../../assets/AWARNESS/img6.jpg';

// JUTE CRAFT TRAINING
import JUTECRAFT from '../../assets/JUTECRAFT/JUTECRAFT.jpg';

// BAMBOO CRAFT TRAINING
import BAMBOO from '../../assets/BAMBOO/BAMBOO.jpg';
import BAMBOO1 from '../../assets/BAMBOO/img10.jpg';
import BAMBOO2 from '../../assets/BAMBOO/img11.jpg';
import BAMBOO3 from '../../assets/BAMBOO/img12.jpg';
import BAMBOO4 from '../../assets/BAMBOO/img13.jpg';
import BAMBOO5 from '../../assets/BAMBOO/img14.jpg';
import BAMBOO6 from '../../assets/BAMBOO/img16.jpg';
import BAMBOO7 from '../../assets/BAMBOO/img2.jpg';
import BAMBOO8 from '../../assets/BAMBOO/img6.jpg';
import BAMBOO9 from '../../assets/BAMBOO/img7.jpg';
import BAMBOO10 from '../../assets/BAMBOO/img8.jpg';

// TRAINING ON BASIC EDUCATION
import BASICEDUCATION from '../../assets/BASICEDUCATION/BASICEDUCATION.jpg';

// GROUP ATRENGHTENING PROGRAMME
import ATRENGHTENING from '../../assets/ATRENGHTENING/ATRENGHTENING.jpg'


// WELFARE SCHEMES FOR ARTISANS
import ARTISANS from '../../assets/ARTISANS/ARTISANS.jpg';








const Images = [
    AWARNESS, AWARNESS1, AWARNESS2, AWARNESS3, AWARNESS4, JUTECRAFT, BAMBOO, BAMBOO1, BAMBOO2,
    BAMBOO3, BAMBOO4, BAMBOO5, BAMBOO6, BAMBOO7, BAMBOO8, BAMBOO9, BAMBOO10, BASICEDUCATION, ATRENGHTENING, ARTISANS,
]


export function GalleryImage() {
    return (
        <>

            <div className="galleryimg grid grid-cols-3 max-md:grid-cols-1 gap-y-8 max-lg:grid-cols-2" >
                {Images.map((value, idx) => (
                    <div className=''>
                        <img src={value} width={400} height={350} className='forgallerycard' />
                    </div>
                ))}
            </div>
        </>
    )
}


